import React from "react";

import { graphql } from "gatsby";

import { gql } from "@apollo/client";

import Vacancy from "../../components/pages/Vacancies/Vacancy";
import VacancyDataProvider from "../../providers/VacancyDataProvider";

// Those news which aren't generated statically are fetched from the gql-API at run-time
const DynamicVacancyPage = ({ params: { slug }, data: { page } }) => {
    return (
        <VacancyDataProvider>
            <Vacancy {...{ id: Number(slug), page }} />
        </VacancyDataProvider>
    );
};
export default DynamicVacancyPage;

export const query = graphql`
    query {
        page: pagesYaml(templateKey: { eq: "pages/stellenangebote" }) {
            ...Page
        }
    }
`;
